import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export function convertDuration(duration) {
    const matches = duration.match(/\d+/g);
    if (!matches) {
        return 0;
    }
    const [hours, minutes] = matches;
    return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
}

export function formatDateExcel(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);  // Création de l'objet Date
}

export function parseNumber(numberString) {
    // Vérifier si numberString est une chaîne, sinon le convertir en chaîne
    if (typeof numberString !== 'string') {
        numberString = numberString.toString();
    }
    return Number(numberString.replace(/[^\d.-]/g, ''));  // Enlever les caractères non numériques et convertir
}

export function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getMonthNumber(date) {
    return date.getMonth() + 1;
}

export function formatDateModal(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
}

export function formatHeureModal(dateOrString) {
    if (typeof dateOrString === "string") {
        if (/^\d{2}:\d{2}$/.test(dateOrString)) {
            return dateOrString;
        }

        const parsedDate = new Date(dateOrString);
        if (!isNaN(parsedDate.getTime())) {
            const hours = parsedDate.getHours().toString().padStart(2, "0");
            const minutes = parsedDate.getMinutes().toString().padStart(2, "0");
            return `${hours}:${minutes}`;
        }

        return "00:00";
    }

    if (dateOrString instanceof Date) {
        const hours = dateOrString.getHours().toString().padStart(2, "0");
        const minutes = dateOrString.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`; // Formatte la Date en "HH:MM"
    }

    return "00:00"; // Par défaut, retourne un format vide en cas d'erreur
}

export const formatTimeWithTimezone = (dateOrString) => {
    if (typeof dateOrString === "string") {
        return dateOrString; // Si déjà une chaîne "HH:MM", retourne directement
    }

    if (dateOrString instanceof Date) {
        const hours = dateOrString.getHours().toString().padStart(2, "0");
        const minutes = dateOrString.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    return "00:00"; // Retourne un format par défaut en cas d'erreur
}

export const formatDateForBackend = (date) => {
    if (!(date instanceof Date)) date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois indexé à partir de 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export async function getNameByDateAndSerial(date, serial) {
    if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
        try {
            const res = await axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            });

            const deviceName = res.data.sort((a, b) => a.username.localeCompare(b.username));
            if (deviceName.length > 0) {
                const device = deviceName.find(device => device.username === serial);
                let selectedDevice = "noname";

                if (device !== undefined) {
                    let NameDevice;

                    try {
                        NameDevice = JSON.parse(device.name_device);
                    } catch (error) {
                        console.error("Erreur de parsing JSON:", error);
                        return selectedDevice;
                    }

                    if (!Array.isArray(NameDevice)) {
                        NameDevice = [NameDevice];
                    }

                    const StartDate = new Date(date);
                    StartDate.setHours(23, 59, 59, 999);

                    const flatten = (input) => {
                        const result = [];
                        input.forEach(item => {
                            if (Array.isArray(item)) {
                                result.push(...flatten(item));
                            } else if (item && typeof item === 'object' && 'timestamp' in item) {
                                result.push(item);
                            }
                        });
                        return result;
                    };

                    const flattenedNameDevice = flatten(NameDevice);

                    if (flattenedNameDevice.length > 0) {
                        flattenedNameDevice.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                        for (const item of flattenedNameDevice) {
                            const itemDate = new Date(item.timestamp);

                            if (itemDate <= StartDate) {
                                selectedDevice = item.nameDevice;
                            } else {
                                break;
                            }
                        }
                    }
                }

                let str = selectedDevice;
                if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                    str = `${str}(${serial})`;
                }
                return str;
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des devices:", error);
            return "Erreur";
        }
    }
    else {
        try {
            const res = await axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            });

            const deviceName = res.data.sort((a, b) => a.username.localeCompare(b.username));
            if (deviceName.length > 0) {
                const device = deviceName.find(device => device.username === serial);
                let selectedDevice = "noname";

                if (device !== undefined) {
                    let NameDevice;

                    try {
                        NameDevice = JSON.parse(device.name_device);
                    } catch (error) {
                        console.error("Erreur de parsing JSON:", error);
                        return selectedDevice;
                    }

                    if (!Array.isArray(NameDevice)) {
                        NameDevice = [NameDevice];
                    }

                    const StartDate = new Date(date);
                    StartDate.setHours(23, 59, 59, 999);

                    const flatten = (input) => {
                        const result = [];
                        input.forEach(item => {
                            if (Array.isArray(item)) {
                                result.push(...flatten(item));
                            } else if (item && typeof item === 'object' && 'timestamp' in item) {
                                result.push(item);
                            }
                        });
                        return result;
                    };

                    const flattenedNameDevice = flatten(NameDevice);

                    if (flattenedNameDevice.length > 0) {
                        flattenedNameDevice.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                        for (const item of flattenedNameDevice) {
                            const itemDate = new Date(item.timestamp);

                            if (itemDate <= StartDate) {
                                selectedDevice = item.nameDevice;
                            } else {
                                break;
                            }
                        }
                    }
                }

                let str = selectedDevice;
                if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                    str = `${str}(${serial})`;
                }
                return str;
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des devices:", error);
            return "Erreur";
        }
    }
    return "noname"; // Fallback si aucun rôle ADMIN
}

export function flattenArray(arr) {
    return arr.reduce((acc, val) => {
        return Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val);
    }, []);
}

/**
 * Traite les données de périodes journalières et agrège les informations.
 *
 * @param {Array} data - Le résultat de la requête API (tableau d'objets report).
 * @param {Array} devices - Le tableau contenant les informations sur les devices.
 * @returns {Object} Un objet contenant les totaux calculés :
 *                   - total_load_transported
 *                   - time_stop (temps d'arrêt)
 *                   - time_work (temps de travail)
 *                   - total_rotcnt (total des rotations)
 *                   - total_distance (distance totale en km)
 *                   - average_timeheating (temps moyen de chauffage)
 */
export function processDailyPeriodsData(data, devices) {
    let total_distance = 0;
    let total_rotcnt = 0;
    let total_timeheating = 0;
    let heating_count = 0;
    let time_work = 0;
    let time_post = 0;
    let total_load_transported = 0;
  
    data.forEach(report => {
      // Parcours des périodes de chaque rapport
      report.periods.forEach(period => {
        total_distance += period.distance;
        // Pour certains types de device (non égal à 24), on cumule le temps de travail
        if (report.device_type !== 24) {
          time_work += period.time_work;
          const startTime = new Date(period.start);
          const stopTime = new Date(period.stop);
          const difference = stopTime - startTime;
          time_post += difference / 1000; // conversion en secondes
        }
      });
  
      // Accumuler le total des rotations s'il y a un événement de production
      if (report.prod_event) {
        total_rotcnt += report.prod_event.total_rotcnt || 0;
  
        // Calcul du total transporté :
        // On recherche dans le tableau devices l'appareil correspondant au serial
        // et si trouvé, on applique la formule ((payload * rotations) / 1000)
        if (report.prod_event.total_rotcnt > 0) {
          const device = devices.find(d => d.username === report.serial);
          if (device) {
            total_load_transported += (device.payload * report.prod_event.total_rotcnt) / 1000;
          }
        }
      }
  
      total_timeheating += report.time_heating;
      heating_count++;
    });
  
    const average_timeheating = heating_count > 0 ? total_timeheating / heating_count : 0;
  
    return {
      total_load_transported,
      time_stop: time_post - time_work,
      time_work,
      total_rotcnt,
      total_distance: total_distance / 1000, // conversion en kilomètres
      average_timeheating
    };
  }