import React, { useState, useEffect, Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import axios from "axios";
import routes from "../../hook/routes.js";
import Logout from "@mui/icons-material/Logout";

const apiUrl = process.env.REACT_APP_API_URL;

function Header() {
  const location = useLocation();
  const [username, setUsername] = useState('');

  const getUserName = async() => {
    const userId = sessionStorage.getItem('userId');
    try {
      const response = await axios.get(`${apiUrl}/api/user/username/${userId}`, {
        headers: { 'x-access-token': sessionStorage.getItem("token") }
      });
      // console.log(response);
      setUsername(response.data.username);
    } catch (error) {
      console.log(error);
    }
  }
  getUserName();

  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.removeItem('loginTime');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('isConnected');
    window.location.reload(false);
  };

  const startTimer = () => {
    // Récupérer le timestamp de la connexion
    const loginTime = sessionStorage.getItem('loginTime');

    if (!loginTime) {
      // L'utilisateur n'est pas connecté, ne pas lancer le compte à rebours
      return;
    }

    // Définir la durée du compte à rebours en millisecondes (24 heures)
    const duration = 24 * 60 * 60 * 1000;

    // Calculer le temps restant avant expiration
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - loginTime;
    const remainingTime = duration - elapsedTime;

    if (remainingTime <= 0) {
      // Le temps est écoulé, déconnecter l'utilisateur
      sessionStorage.removeItem('loginTime');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('isConnected');
      window.location.reload(false);
      return;
    }

    // Mettre à jour l'affichage du compte à rebours
    const countdownElement = document.getElementById('countdown');

    const remainingSeconds = Math.floor(remainingTime / 1000);
    const remainingMinutes = Math.floor(remainingSeconds / 60);
    const remainingHours = Math.floor(remainingMinutes / 60);
    if (countdownElement !== null) {
      countdownElement.innerHTML = `${remainingHours % 24} heures, ${remainingMinutes % 60} minutes, ${remainingSeconds % 60} secondes`;
    }

    // Attendre une seconde avant de mettre à jour le compte à rebours
    setTimeout(startTimer, 1000);
  }
  startTimer();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            <Nav.Item>
              <Nav.Link
                data-toggle="dropdown"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                className="m-0"
              >

                <span className="d-lg-none ml-1">Dashboard</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <div style={{ marginRight: '5%' }}>
          <strong>Logged as :<br/></strong>
          {username? username : ''}
        </div>
        {/* <div style={{ marginRight: '5%' }}>
          <strong>Session Expiration :</strong>
          <div id="countdown"> </div>
        </div> */}
        <Button style={{ width: '5%' }} onClickCapture={logout} variant="contained" color="error" ><Logout /></Button>
      </Container>
    </Navbar>
  );
}

export default Header;
