import React from "react";
import "./App.css";
import { DashBoardLayout } from "Components/admin/DashboardLayout";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Favicon from "react-favicon";
import favicon from "Public/images/favicon.ico";
import { FilteredSpeedingProvider } from 'Components/FilteredSpeedingContext';

function App() {
  const token = sessionStorage.getItem("token") != null;
  const isConnected = sessionStorage.getItem("isConnected") != null;
  return <>
    <Favicon url={favicon} />
    <FilteredSpeedingProvider>
      <DashBoardLayout token={token} isConnected={isConnected} />
    </FilteredSpeedingProvider>
  </>

}

export default App;
