import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Select, MenuItem } from "@mui/material";
import TimelineProd from "../../TimelineProd";
import fr from "date-fns/locale/fr";
import "./ManageRotation.css";

const apiUrl = process.env.REACT_APP_API_URL;

function ManageRotationV2() {
    const location = useLocation();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedWorksite, setSelectedWorksite] = useState("");
    const [Worksite, setWorksite] = useState([]);
    const [rotation, setRotation] = useState([]);
    const [serials, setSerials] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deviceName, setDeviceName] = useState([]);
    const [Picto, setPicto] = useState([]);

    useEffect(() => {
        // Fetch worksite data
        axios.get(`${apiUrl}/api/user/worksite`, {
            headers: { "x-access-token": sessionStorage.getItem("token") },
        })
            .then((res) => {
                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
                if (worksite.length > 0) {
                    setSelectedWorksite(worksite[0].worksiteid);
                }
            });
        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data);
            })

        // Fetch devices data
        let role;
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            role = 'admin'
        }
        else {
            role = 'user';
        }
        axios.get(`${apiUrl}/api/${role}/device`, {
            headers: { "x-access-token": sessionStorage.getItem("token") },
        })
            .then((res) => {
                // console.log(res.data);
                setDevices(res.data);
            });
    }, []);

    useEffect(() => {
        if (selectedWorksite !== "") {
            const start = new Date(selectedStartDate);
            start.setHours(0, 0, 1);

            const stop = new Date(selectedStartDate);
            stop.setHours(23, 59, 59);

            // Fetch production events and serials
            axios.post(
                `${apiUrl}/api/user/prodevent`,
                { startdate: start, stopdate: stop, selectedWorksite },
                { headers: { "x-access-token": sessionStorage.getItem("token") } }
            )
                .then((res) => {
                    setRotation(res.data);
                    setSerials(res.data.map((item) => item.serial));
                });
        }
    }, [selectedWorksite, selectedStartDate]);

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };

    function getPictoByTypeDevice(type) {
        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    const generatePeriods = () => {
        // const serials = Array.from({ length: 10 }, (_, i) => `serial-${i + 1}`);
        const periods = {};

        serials.forEach((serial) => {
            periods[serial] = [
                {
                    id: `${serial}-1`,
                    start: new Date(new Date().setHours(8, 0, 0)).toISOString(), // ISO format
                    end: new Date(new Date().setHours(10, 0, 0)).toISOString(),
                    content: `${serial} - Période 1`,
                    className: "timeline-period",
                },
                {
                    id: `${serial}-2`,
                    start: new Date(new Date().setHours(11, 0, 0)).toISOString(),
                    end: new Date(new Date().setHours(13, 0, 0)).toISOString(),
                    content: `${serial} - Période 2`,
                    className: "timeline-period",
                },
            ];
        });

        return periods;
    };

    const periods = generatePeriods();

    return (
        <div>
            <div>
                <label className="labelStartManageRotation">Date : </label>
                <span className="spanStartManageRotation">
                    <DatePicker
                        locale={fr}
                        dateFormat="dd/MM/yyyy"
                        selected={selectedStartDate}
                        onChange={handleStartDateChange}
                    />
                </span>
                <Select
                    className="selectWorksiteManageRotation"
                    value={selectedWorksite}
                    onChange={handleWorksiteChange}
                >
                    {Worksite.map((worksite, index) => (
                        <MenuItem key={index} value={worksite.worksiteid}>
                            {worksite.worksitename}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <div className="timelineprod-container" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {serials.map((serial, index) => (
                    <div key={serial} className="timeline-wrapper" style={{ padding: "10px" }}>
                        <div className="timeline-header" style={{ marginBottom: "10px", textAlign: "center" }}>
                            <img
                                src={getPictoByTypeDevice(deviceName.find((d) => d.username === serial)?.device_type)}
                                alt="Device Icon"
                                className="device-icon"
                                style={{ maxWidth: "100px", marginRight: "10px" }}
                            />
                            <span>({serial})</span>
                        </div>
                        <TimelineProd periods={periods} date={selectedStartDate} serial={serial} />
                    </div>
                ))}
            </div>

        </div>
    );
}

export default ManageRotationV2;