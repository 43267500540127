import React, { useState, useEffect } from 'react';
import axios from "axios";
import useWebSocket from "react-use-websocket"
import StyledTextarea from '@mui/material/TextareaAutosize';
import { TextField } from '@mui/material';
const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;
const Terminal = () => {
    let tmptab = window.location.href.split('/');
    const [terminalDatas, setTerminalDatas] = useState('');
    const [our_serial_keepAlive, setOur_serial_keepAlive] = useState([]);
    const userid = sessionStorage.getItem("userId")
    const channelname = "terminal";
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname },
            share: false,
            shouldReconnect: () => true,

        },
    )

    const channelnameConnected = "connected";
    const { lastJsonMessage: lastJsonMessageConnected } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname: channelnameConnected },
            share: false,
            shouldReconnect: () => true,

        },
    )
    useEffect(() => {
        if (lastJsonMessageConnected !== null) {
            let find = false;
            Object.entries(lastJsonMessageConnected).forEach(element => {

                if ((element[1].SerialStr === currentTerminalSerial) && (element[1].IsConnected)) {
                    find = true;
                    setOur_serial_keepAlive(element[1]);
                }
            });
            if (!find)
                setOur_serial_keepAlive([]);
        }
    }, [lastJsonMessageConnected])

    const currentTerminalSerial = tmptab[tmptab.length - 1];
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            setTerminalDatas(prevTerminalDatas => {
                let newData = prevTerminalDatas;
                data.forEach(element => {
                    if (element.serial === currentTerminalSerial)
                        newData += element.msg;
                    else
                        console.log('bad serial for msg');
                });
                return newData;
            });
        }
    }, [lastJsonMessage])

    const sendText = (msg) => {
        axios.post(apiUrl + `/api/admin/TerminalSend`, { serial: currentTerminalSerial, cmd: msg }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                let str = terminalDatas;
                str += '>> ' + msg;
                setTerminalDatas(str);
            });
    };

    function TimeRemaining(keepAlive) {
        if (keepAlive) {
            const now = Date.now();
            const timeDiff = now - new Date(keepAlive);

            // Calculer le temps restant (1 minute = 60 * 1000 ms)
            const remaining = Math.max(0, 60 - Math.floor(timeDiff / 1000));
            return remaining;
        }
    }

    return (
        <div>
            <div>
                <p>Serial: {currentTerminalSerial} Connection status:xx {our_serial_keepAlive.BoolMsgTerminalPending && our_serial_keepAlive.MsgTerminal !== null ? <> En Attente de KeepAlive {TimeRemaining(our_serial_keepAlive.last_keepAlive.timestamp)} s</> : ""}</p>
            </div>
            <div>
                <StyledTextarea readOnly style={{ width: '100%' }} aria-label="minimum height" minRows={20} maxRows={20} value={terminalDatas} />
            </div>
            <div>
                <TextField id="outlined-basic" style={{ width: '100%' }} label="Type here (enter to send)" variant="outlined" onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        sendText(ev.target.value + '\r\n');
                        ev.target.value = '';
                        ev.preventDefault();
                    }
                }} />
            </div>
        </div>
    );
};


export default Terminal;



