import React, { createContext, useContext, useState } from 'react';

// Création du contexte
const FilteredSpeedingContext = createContext();

// Hook pour accéder au contexte
export const useFilteredSpeeding = () => {
  return useContext(FilteredSpeedingContext);
};

// Fournisseur du contexte pour encapsuler l'application
export const FilteredSpeedingProvider = ({ children }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [checkedDays, setCheckedDays] = useState([]);

  return (
    <FilteredSpeedingContext.Provider value={{ filteredData, setFilteredData, checkedDays, setCheckedDays }}>
      {children}
    </FilteredSpeedingContext.Provider>
  );
};
