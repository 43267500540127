import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import sidebarImage from "../../public/images/sidebar-4.jpg";
import Footer from "../components/Footer/Footer";
import { dashboardRoutes } from "./routes";
import Navbar from "../components/NavBars/Navbar";
import ConstructionPage from "../components/ConstructionPage";
import axios from "axios";
import { useFilteredSpeeding } from 'Components/FilteredSpeedingContext';

export function ConnectedLayout(props) {
    const [image, setImage] = useState(sidebarImage);
    const [color, setColor] = useState("black");
    const [hasImage, setHasImage] = useState(true);
    const [constructionPage, setConstructionUserPage] = useState([]);
    const [worksitebyclient, setWorksiteByClient] = useState([]);
    const [worksite, setWorksite] = useState([]);
    const [isSpeeding, setIsSpeeding] = useState(false);
    const { filteredData, setFilteredData, checkedDays, setCheckedDays } = useFilteredSpeeding();
    const location = useLocation();
    const mainPanel = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    let DashboardRoutes;
    const daysToCheck = 7;

    useEffect(() => {
        setCheckedDays(daysToCheck); // Pour la vérification des dépassements de vitesse (Safety)
        axios.get(apiUrl + `/api/user/constructionuserpages`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setConstructionUserPage(res.data);
            })
        axios.get(apiUrl + `/api/user/worksitebyclient`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const worksitebyclient = res.data;
                setWorksiteByClient(worksitebyclient);
            })
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setWorksite(res.data);
            })
    }, [apiUrl]);

    useEffect(() => {
        axios.get(apiUrl + `/api/user/deviceindicator`,
            {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            }
        )
            .then(function (response) {
                if (worksite.length > 0) {
                    const filteredData = filterDataBySpeedMaxAndPeriod(response.data, worksite, daysToCheck);
                    setFilteredData(filteredData);  // Stocker filteredData dans le contexte
                    if (filteredData.length > 0) {
                        setIsSpeeding(true);
                    }
                    else {
                        setIsSpeeding(false);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [worksite]);

    // Filtrer les données des devices par speed_max et période
    const filterDataBySpeedMaxAndPeriod = (deviceData, worksiteByClient, daysAgo) => {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - daysAgo); // Plage de temps définie par "daysAgo"

        return deviceData.filter(device => {
            // Comparer le worksiteid et récupérer le worksite correspondant
            const worksite = worksiteByClient.find(worksite => worksite.worksiteid === device.worksiteid);

            if (worksite) {
                // Extraire les paramètres de sécurité du worksite
                const worksiteParams = JSON.parse(worksite.worksiteparameters);
                const speedMaxSiteGlobal = worksiteParams.security?.Speedmax_site_global;
                // Si "Speedmax_site_global" existe, on continue
                if (speedMaxSiteGlobal) {
                    // Comparer la période avec les dates "start" et "stop" du device
                    const periodStart = new Date(device.start);
                    const periodEnd = new Date(device.stop);
                    const isWithinPeriod = (periodStart >= startDate && periodEnd <= today);

                    // Vérifier la comparaison des vitesses si la période est correcte
                    if (isWithinPeriod) {
                        // console.log(device.speed_max <= parseInt(speedMaxSiteGlobal, 10));
                        return device.speed_max > parseInt(speedMaxSiteGlobal, 10);
                    }
                }
            }
            // Si aucun worksite ou speed_max n'est valide, on exclut le device
            return false;
        });
    };

    const updateDashboardRoutes = (dashboardRoutes, construction) => {
        return dashboardRoutes.map(route => {
            const constructionEntry = construction.find(entry => entry.name_page.trim() === route.name.trim());

            // Vérification de l'alerte isSpeeding
            if (route.name === "Safety" && isSpeeding) {
                return {
                    ...route,
                    background: "red", // Couleur pour l'alerte
                };
            }

            if (constructionEntry && constructionEntry.construction) {
                return {
                    ...route,
                    construction: true
                };
            } else if (route.subMenuItems && route.subMenuItems.length > 0) {
                // Vérifie également les sous-menus
                const updatedSubMenuItems = route.subMenuItems.map(subMenu => {
                    const subMenuConstructionEntry = construction.find(entry => entry.name_page.trim() === subMenu.name.trim());
                    if (subMenuConstructionEntry && subMenuConstructionEntry.construction) {
                        return {
                            ...subMenu,
                            construction: subMenuConstructionEntry.construction,
                        };
                    } else {
                        return subMenu;
                    }
                });
                return {
                    ...route,
                    subMenuItems: updatedSubMenuItems
                };
            } else {
                return route;
            }
        });
    };

    if (constructionPage.length > 0 && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
        DashboardRoutes = updateDashboardRoutes(dashboardRoutes, constructionPage);
    } else {
        DashboardRoutes = dashboardRoutes;
    }

    const getRoutes = (routes) => {
        const renderRoute = (prop, key) => {
            if (prop.construction) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        element={props.isLogin ? <ConstructionPage /> : <Navigate replace to="/" />}
                        key={key}
                    />
                );
            } else {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        element={props.isLogin ? <prop.component /> : <Navigate replace to="/" />}
                        key={key}
                    />
                );
            }
        };

        return routes.flatMap((prop, key) => {
            let routeComponents = [];
            if (prop.layout !== "") {
                routeComponents.push(renderRoute(prop, key));
                if (prop.subMenuItems && prop.subMenuItems.length > 0) {
                    routeComponents = [
                        ...routeComponents,
                        ...prop.subMenuItems.map((subProp, subKey) => renderRoute(subProp, `${key}-${subKey}`))
                    ];
                }
            }
            return routeComponents;
        });
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainPanel.current.scrollTop = 0;
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            const element = document.getElementById("bodyClick");
            element.parentNode.removeChild(element);
        }
    }, [location]);

    return (
        <div className="wrapper">
            <Sidebar
                color={color}
                image={hasImage ? image : ""}
                routes={DashboardRoutes}
                isSpeeding={isSpeeding}
            />
            <div className="main-panel" ref={mainPanel}>
                <Navbar />
                <div className="content">
                    <Routes>{getRoutes(DashboardRoutes)}</Routes>
                </div>
                <Footer />
            </div>
        </div>
    );
}
