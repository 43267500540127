
export function convertDuration(duration) {
    const matches = duration.match(/\d+/g);
    if (!matches) {
        return 0;
    }
    const [hours, minutes] = matches;
    return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
}

export function formatDateExcel(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);  // Création de l'objet Date
}

export function parseNumber(numberString) {
    // Vérifier si numberString est une chaîne, sinon le convertir en chaîne
    if (typeof numberString !== 'string') {
        numberString = numberString.toString();
    }
    return Number(numberString.replace(/[^\d.-]/g, ''));  // Enlever les caractères non numériques et convertir
}

export function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getMonthNumber(date) {
    return date.getMonth() + 1;
}

