import React, { useEffect, useState } from 'react';
import axios from "axios";
import TextField from "@mui/material/TextField";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import "./Device.css";
import { flattenArray } from "./../../utils/utils";
const apiUrl = process.env.REACT_APP_API_URL;

const DevicePage = () => {
    const [deviceName, setDeviceName] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValuePassword, setSelectedValuePassword] = useState('');
    const [selectedValueAdmin, setSelectedValueAdmin] = useState('');
    const [selectedValueDelete, setSelectedValueDelete] = useState('');
    const [selectedValueSerialWorksite, setSelectedValueSerialWorksite] = useState('');
    const [selectedValueName, setSelectedValueName] = useState('');
    const [selectedValueNameAdmin, setSelectedValueNameAdmin] = useState('');
    const [selectedValuePayload, setSelectedValuePayload] = useState('');
    const [selectedValuePayloadAdmin, setSelectedValuePayloadAdmin] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [Client, setClient] = useState([]);
    const [HardwareType, setHardwareType] = useState([]);
    const [speedMaxDevice, setSpeedMaxDevice] = useState(0);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [clientId, setClientid] = useState('');
    const [HardwareId, setHardwareId] = useState('');
    const [clientIdAdmin, setClientidAdmin] = useState('');
    const [hardwareIdAdmin, setHardwareTypeidAdmin] = useState('');
    const [nameDevice, setNameDevice] = useState('noname');
    const [deviceAdmin, setDeviceAdmin] = useState([]);


    useEffect(() => {
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        }
        else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        }
    }, [selectedValue, selectedValueSerialWorksite, selectedValueAdmin]);

    useEffect(() => {
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceAdmin(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        }
    }, []);


    useEffect(() => {
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/client`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setClient(res.data.sort((a, b) => a.nomClient.localeCompare(b.nomClient)));
                })

            axios.get(apiUrl + `/api/admin/hardwaretype`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setHardwareType(res.data);
                })
        }
    }, []);

    const handleDeviceNameChange = (e) => {
        setSelectedValueName(e.target.value);
    };

    const handlePayloadChange = (e) => {
        setSelectedValuePayload(e.target.value);
    };

    const handleDeviceNameChangeAdmin = (e) => {
        setSelectedValueNameAdmin(e.target.value);
    };

    const handlePasswordChangeAdmin = (e) => {
        setSelectedValuePassword(e.target.value);
    };

    const handlePayloadChangeAdmin = (e) => {
        setSelectedValuePayloadAdmin(e.target.value);
    };

    const handleSpeedChangeAdmin = (e) => {
        setSpeedMaxDevice(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const serial = e.target.elements.deviceId.value;
        const nameDevice = e.target.elements.deviceName.value;
        const payload = e.target.elements.payload.value;

        const deviceToRetrieve = deviceName.find(device => device.username === serial);
        let deviceHistory = deviceToRetrieve ? JSON.parse(deviceToRetrieve.name_device) : [];

        const currentDateTime = new Date();

        const deviceSettings = {
            speed: parseInt(speedMaxDevice)
        };

        const newDeviceNameEntry = {
            nameDevice: nameDevice,
            timestamp: currentDateTime,
        };

        if (!Array.isArray(deviceHistory)) {
            deviceHistory = [];
        }

        deviceHistory.push(newDeviceNameEntry);
        axios.post(apiUrl + "/api/user/updatedevice", {
            serial: serial,
            name_device: JSON.stringify(deviceHistory),
            payload: payload,
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert(response.data.message);
                setSelectedValue('');
                setSelectedValueName('');
                setSelectedValuePayload('');
            })
            .catch((error) => {
                console.error("Erreur lors de l'update :", error);
                alert("Erreur lors de l'update");
            });
    };

    const handleSubmitAdmin = (e) => {
        e.preventDefault();

        const serial = e.target.elements.deviceId.value;
        const nameDevice = e.target.elements.deviceName.value;
        const payload = e.target.elements.payload.value;
        let passw = e.target.elements.passw ? e.target.elements.passw.value : "";

        const deviceToRetrieve = deviceName.find(device => device.username === serial);
        let deviceHistory = deviceToRetrieve ? JSON.parse(deviceToRetrieve.name_device) : [];
        deviceHistory = flattenArray(deviceHistory);

        const currentDateTime = new Date();

        const deviceSettings = {
            speed: parseInt(speedMaxDevice)
        };

        const newDeviceNameEntry = {
            nameDevice: nameDevice,
            timestamp: currentDateTime,
        };

        if (!Array.isArray(deviceHistory)) {
            deviceHistory = [];
        }

        deviceHistory.push(newDeviceNameEntry);

        axios.post(apiUrl + "/api/admin/updatedevice", {
            serial: serial,
            name_device: JSON.stringify(deviceHistory),
            payload: payload,
            idClient: clientIdAdmin,
            id_hardware_type: hardwareIdAdmin,
            passw: passw,
            settings: JSON.stringify(deviceSettings),
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert(response.data.message);
                setSelectedValueAdmin('');
                setSelectedValueNameAdmin('');
                setSelectedValuePayloadAdmin('');
                setClientidAdmin("");
                setHardwareTypeidAdmin("");
                setSelectedValuePassword("")
                setIsChecked(false)
                setSpeedMaxDevice(0);
            })
            .catch((error) => {
                console.error("Erreur lors de l'update :", error);
                alert("Erreur lors de l'update");
            });
    };

    const handleSubmitDelete = (e) => {
        e.preventDefault();

        const serial = e.target.elements.deviceId.value;


        axios.post(apiUrl + "/api/admin/deletedevice", {
            serial: serial,
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert(response.data.message);
                setSelectedValueDelete("")
                window.location.reload(false);

            })
            .catch((error) => {
                console.error("Erreur lors de la suppression :", error);
                alert("Erreur lors de la suppression");
            });
    };

    const handleAddDeviceSubmit = (e) => {
        e.preventDefault();

        const username = e.target.elements.usernameid.value;
        const password = e.target.elements.passwordid.value;
        const clientId = e.target.elements.clientid.value;
        const idHardwareType = e.target.elements.hardwareType.value;
        const nameDevice = e.target.elements.NameDevice.value;
        const currentDateTime = new Date(0);

        const DeviceNameTimeStamp = {
            nameDevice: nameDevice,
            timestamp: currentDateTime,
        };

        axios.post(apiUrl + "/api/admin/adddevice", {
            username: username,
            passw: password,
            idClient: clientId,
            idHardwareType: idHardwareType,
            name_device: JSON.stringify(DeviceNameTimeStamp)
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert("Device crée")
                setUsername('');
                setPassword('');
                setClientid('');
                setHardwareId('')
                setNameDevice('');
                window.location.reload(false);
            })
            .catch((error) => {
                console.error("Erreur lors de l'ajout :", error);
                alert("Erreur lors de l'ajout");
            });
    };

    const handleAddWorksiteSubmit = (e) => {
        e.preventDefault();

        const worksitename = e.target.elements.worksitename.value;
        const worksitedesc = e.target.elements.worksitedesc.value;

        axios.post(apiUrl + "/api/admin/addworksite", {
            worksitename: worksitename,
            worksitedesc: worksitedesc,
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                alert("Worksite crée")
                setWorksiteName("");
                setWorksiteDesc("");
            })
            .catch((error) => {
                console.error("Erreur lors de l'ajout :", error);
                alert("Erreur lors de l'ajout");
            });
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);

        const device = deviceName.find(device => device.username === event.target.value);
        if (device.name_device !== null) {
            const DeviceName = JSON.parse(device.name_device);
            let lastDevice;
            if (DeviceName.length > 1)
                lastDevice = DeviceName[DeviceName.length - 1].nameDevice;
            else
                lastDevice = DeviceName.nameDevice
            setSelectedValueName(lastDevice);
        } else {
            setSelectedValueName('');
        }

        if (device.payload !== null) {
            setSelectedValuePayload(device.payload)
        } else {
            setSelectedValuePayload(0)
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleChangeAdmin = (event) => {
        setSelectedValueAdmin(event.target.value);
        const device = deviceName.find(device => device.username === event.target.value);
        let speedValue = 0;
        if (device.settings !== null) {
            const settings = JSON.parse(device.settings);
            if (settings.hasOwnProperty('speed')) {
                // La clé "speed" existe dans l'objet JSON
                speedValue = settings['speed']; // Obtenez la valeur associée à la clé "speed"
            }
        }
        setSpeedMaxDevice(speedValue);
        setClientidAdmin(device.client.idClient)
        setHardwareTypeidAdmin(device.hardware_type.id_hardware_type);
        if (device.name_device !== null) {
            const DeviceName = JSON.parse(device.name_device);
            let lastDevice;
            if (DeviceName.length > 1)
                lastDevice = DeviceName[DeviceName.length - 1].nameDevice;
            else
                lastDevice = DeviceName.nameDevice
            setSelectedValueNameAdmin(lastDevice);
        } else {
            setSelectedValueNameAdmin('');
        }

        if (device.payload !== null) {
            setSelectedValuePayloadAdmin(device.payload)
        } else {
            setSelectedValuePayloadAdmin(0)
        }
    };

    const handleChangeDelete = (event) => {
        setSelectedValueDelete(event.target.value);
    }

    function GetLastName(name_device) {
        const DeviceName = JSON.parse(name_device);
        let lastDevice;
        if (DeviceName.length > 1)
            lastDevice = DeviceName[DeviceName.length - 1].nameDevice;
        else
            lastDevice = DeviceName.nameDevice;

        return lastDevice;
    }

    function getNamebySerial(serial) {
        const device = deviceAdmin.find(device => device.username === serial);
        console.log();
        let DeviceName = 'noname';
        if (device) {
            DeviceName = JSON.parse(device.name_device);
        }
        let lastDevice;
        if (DeviceName.length > 1)
            lastDevice = DeviceName[DeviceName.length - 1].nameDevice;
        else
            lastDevice = DeviceName.nameDevice;

        return lastDevice;
    }

    return (
        <>
            <div className="divDevice">
                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
                    <div className='cardDevice'>
                        <h1 className='centerDevice'>Modification</h1>
                        <form onSubmit={handleSubmitAdmin}>
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="deviceId-label">Serial:</InputLabel>
                                    <Select name="deviceId" id="deviceId"
                                        labelId="deviceId-label"
                                        value={selectedValueAdmin}
                                        onChange={handleChangeAdmin}
                                    >
                                        <MenuItem value="">Sélectionnez un serial</MenuItem>
                                        {deviceName.map((serial, index) => (
                                            <MenuItem key={index} value={serial.username}>{serial.username} ({getNamebySerial(serial.username)})</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <TextField
                                    id="deviceName"
                                    label="Nom de l'appareil"
                                    value={selectedValueNameAdmin}
                                    onChange={handleDeviceNameChangeAdmin}
                                    fullWidth
                                />
                            </div>
                            <br />
                            <div>
                                <TextField
                                    id="payload"
                                    label="Charge utile"
                                    value={selectedValuePayloadAdmin}
                                    onChange={handlePayloadChangeAdmin}
                                    fullWidth
                                />
                            </div>
                            <br />
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="client-label">Hardware Type</InputLabel>
                                    <Select
                                        labelId="hardwareType-label"
                                        id="hardwaretypeid"
                                        name="hardwaretypeid"
                                        value={hardwareIdAdmin}
                                        onChange={(e) => setHardwareTypeidAdmin(e.target.value)}
                                        required
                                    >
                                        <MenuItem value="">Sélectionnez un client</MenuItem>
                                        {HardwareType.map((hardwareType, index) => (
                                            <MenuItem key={index} value={hardwareType.id_hardware_type}>{hardwareType.nom_hardware_type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <TextField
                                    id="speed"
                                    label="Vitesse maximum"
                                    value={speedMaxDevice}
                                    onChange={handleSpeedChangeAdmin}
                                    type="number"
                                    fullWidth
                                />
                            </div>
                            <br />
                            <FormControl className='formDevice'>
                                <InputLabel id="client-label">Client</InputLabel>
                                <Select
                                    labelId="client-label"
                                    id="clientid"
                                    name="clientid"
                                    value={clientIdAdmin}
                                    onChange={(e) => setClientidAdmin(e.target.value)}
                                    required
                                >
                                    <MenuItem value="">Sélectionnez un client</MenuItem>
                                    {Client.map((client, index) => (
                                        <MenuItem key={index} value={client.idClient}>{client.nomClient}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    Modifier le mot de passe
                                </label>
                                {isChecked && (
                                    <div>
                                        <TextField
                                            id="passw"
                                            type="password"
                                            label="mot de passe"
                                            value={selectedValuePassword}
                                            onChange={handlePasswordChangeAdmin}
                                            fullWidth
                                        />
                                    </div>
                                )}
                            </div>
                            <br />
                            <Button type="submit" variant="contained" color="primary" className='width100Device'>
                                Mettre à jour
                            </Button>
                        </form>
                    </div> : ""}

                {sessionStorage.getItem("Roles") === "ROLE_USER" ?
                    <div className='cardDevice'>
                        <h1 className='centerDevice'>Modifier un appareil</h1>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="deviceId-label">Serial:</InputLabel>
                                    <Select name="deviceId" id="deviceId"
                                        labelId="deviceId-label"
                                        value={selectedValue}
                                        onChange={handleChange}
                                    >

                                        <MenuItem value="">Sélectionnez un serial</MenuItem>
                                        {deviceName.map((serial, index) => (
                                            <MenuItem key={index} value={serial.username}>{serial.username}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <TextField
                                    id="deviceName"
                                    label="Nom de l'appareil"
                                    value={selectedValueName}
                                    onChange={handleDeviceNameChange}
                                    fullWidth
                                />
                            </div>
                            <br />
                            <div>
                                <TextField
                                    id="payload"
                                    label="Charge utile"
                                    value={selectedValuePayload}
                                    onChange={handlePayloadChange}
                                    fullWidth
                                />
                            </div>
                            <br />
                            <Button type="submit" variant="contained" color="primary" className='width100Device'>
                                Mettre à jour
                            </Button>
                        </form>
                    </div> : ""}

                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
                    <div className='cardDevice2'>
                        <h1 className='centerDevice' >Ajout</h1>
                        <form onSubmit={handleAddDeviceSubmit}>
                            <div>
                                <TextField className='formDevice'
                                    id='usernameid'
                                    label="Numero de série"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    inputProps={{ maxLength: 6 }}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='passwordid'
                                    type="password"
                                    label="Mot de passe"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="client-label">Client</InputLabel>
                                    <Select
                                        labelId="client-label"
                                        id="clientid"
                                        name="clientid"
                                        value={clientId}
                                        onChange={(e) => setClientid(e.target.value)}
                                        required
                                    >
                                        <MenuItem value="">Sélectionnez un client</MenuItem>
                                        {Client.map((client, index) => (
                                            <MenuItem key={index} value={client.idClient}>{client.nomClient}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="client-label">Hardware Type</InputLabel>
                                    <Select
                                        labelId="client-label"
                                        id="hardwareType"
                                        name="hardwareType"
                                        value={HardwareId}
                                        onChange={(e) => setHardwareId(e.target.value)}
                                        required
                                    >
                                        <MenuItem value="">Sélectionnez un type hardware</MenuItem>
                                        {HardwareType.map((hardwaretype, index) => (
                                            <MenuItem key={index} value={hardwaretype.id_hardware_type}>{hardwaretype.nom_hardware_type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='NameDevice'
                                    label="Nom de l'appareil"
                                    value={nameDevice}
                                    onChange={(e) => setNameDevice(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <Button type="submit" variant="contained" color="primary" className='width100Device'>
                                    Ajouter
                                </Button>
                            </div>
                        </form>
                    </div> : ""
                }

                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
                    <div className='cardDevice2'>
                        <h1 className='centerDevice'>Suppression</h1>
                        <form onSubmit={handleSubmitDelete}>
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="deviceId-label">Serial:</InputLabel>
                                    <Select name="deviceId" id="deviceId"
                                        labelId="deviceId-label"
                                        value={selectedValueDelete}
                                        onChange={handleChangeDelete}
                                    >

                                        <MenuItem value="">Sélectionnez un serial</MenuItem>
                                        {deviceName.map((serial, index) => (
                                            <MenuItem key={index} value={serial.username}>{serial.username}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <Button type="submit" variant="contained" color="primary" className='width100Device'>
                                Suppression
                            </Button>
                        </form>
                    </div> : ""}
            </div>
            <div className='marginTop1Device'>
                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? deviceAdmin.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Device</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Client</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deviceAdmin.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.username}</TableCell>
                                    <TableCell>{GetLastName(item.name_device)}</TableCell>
                                    <TableCell>{item.client.nomClient}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    : "" : ""
                }
            </div>
        </>
    );
};

export default DevicePage;