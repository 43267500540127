import React, { useEffect, useRef } from "react";
import { Timeline } from "vis-timeline/standalone";
import "vis-timeline/dist/vis-timeline-graph2d.min.css";

const TimelineProd = ({ periods, date, serial }) => {
    const timelineRef = useRef();
    const timelineInstance = useRef(null);

    useEffect(() => {
        const container = timelineRef.current;
        const items = periods[serial] || []; // Récupérer les périodes pour le serial donné

        const options = {
            height: "200px",
            start: new Date(date).setHours(0, 0, 0), // Début de la journée
            end: new Date(date).setHours(23, 59, 59), // Fin de la journée
            zoomMin: 3600000, // Minimum zoom (1 heure)
            zoomMax: 86400000, // Maximum zoom (1 jour)
            tooltip: {
                followMouse: true,
            },
        };

        // Supprimer l'instance précédente si elle existe
        if (timelineInstance.current) {
            timelineInstance.current.destroy();
        }
        console.log("Items for serial:", serial, items);

        // Créer une nouvelle instance de Timeline
        timelineInstance.current = new Timeline(container, items, options);

        return () => {
            // Détruire l'instance lors du démontage
            if (timelineInstance.current) {
                timelineInstance.current.destroy();
                timelineInstance.current = null;
            }
        };
    }, [periods, date, serial]);

    return (
        <div
            ref={timelineRef}
            style={{ padding: "10px", margin: "20px 0" }}
        />
    );
};

export default TimelineProd;
