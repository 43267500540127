import React, { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

function Sidebar({ color, image, routes, isSpeeding }) {
  const location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")"
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <div className="logo-img">
            <img src={require("Public/images/logo.png")} alt="..." style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (prop.invisible) return null;
            if (!prop.redirect) {
              return (
                <MenuItem
                  key={key}
                  prop={prop}
                  activeRoute={activeRoute}
                  isSpeeding={isSpeeding}
                />
              );
            }
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

const MenuItem = ({ prop, activeRoute, isSpeeding }) => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const toggleSubMenu = () => {
    if (prop.subMenuItems && prop.subMenuItems.length > 0) {
      setSubMenuVisible(!isSubMenuVisible);
    }
  };

  // Appliquer un style conditionnel basé sur l'état "isSpeeding"
  const menuStyle = prop.name === "Safety" && isSpeeding
    ? { backgroundColor: "red" }  // Couleur personnalisée si alerte
    : { backgroundColor: 'inherit' };

  

  return (
    <li
      className={
        prop.upgrade
          ? "active active-pro"
          : activeRoute(prop.layout + prop.path)
      }
      style = {menuStyle}
    >
      {prop.subMenuItems && prop.subMenuItems.length > 0 ?
        <>
          <div onClick={toggleSubMenu} className="nav-link">
            <i className={prop.icon} />
            <p>{prop.name}</p>
          </div>
          {isSubMenuVisible && (
            <ul className="sub-menu">
              {prop.subMenuItems.map((subItem, index) => (
                <li key={index} className={activeRoute(subItem.layout + subItem.path)} style = {{ backgroundColor: 'inherit' }}>
                  <NavLink
                    to={subItem.layout + subItem.path}
                    className="nav-link"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    style={{
                      backgroundColor: hoveredIndex === index ? "#707070" : "inherit"
                    }}
                  >
                    <i className={subItem.icon} />
                    <p>{subItem.name}</p>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </>
        :
        <NavLink
          to={prop.layout + prop.path}
          className="nav-link"
        // activeClassName="active"
        >
          <i className={prop.icon} />
          <p>{prop.name}</p>
        </NavLink>
      }
    </li>
  );
};

export default Sidebar;
