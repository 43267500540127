import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Timeline from "fix-react-vis-timeline";
// import TimelineProd from "../../TimelineProd";
import fr from "date-fns/locale/fr";
import "./ManageRotation.css";
import { formatDateModal, formatHeureModal, formatTimeWithTimezone, getNameByDateAndSerial, formatDateForBackend } from "./../../utils/utils"
import { parse } from "date-fns";
import Loader from 'Public/images/loader.gif';

const apiUrl = process.env.REACT_APP_API_URL;

function ManageRotationV2() {
    const location = useLocation();
    const [selectedStartDate, setSelectedStartDate] = useState(
        location.state?.start ? new Date(location.state.start) : new Date()
    );
    const [selectedWorksite, setSelectedWorksite] = useState(location.state?.worksite || "");
    const [Worksite, setWorksite] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [stopReasons, setStopReasons] = useState([]);
    const [rotation, setRotation] = useState([]);
    const [serials, setSerials] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deviceNames, setDeviceNames] = useState([]);
    const [Picto, setPicto] = useState([]);
    const [allPeriods, setAllPeriods] = useState([]);
    const [dailyUserDatas, setDailyUserDatas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalRotationOpen, setModalRotationOpen] = useState(false);
    const [modalCreatePeriodOpen, setModalCreatePeriodOpen] = useState(false);
    const [modalCreateStopOpen, setModalCreateStopOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [editedPeriod, setEditedPeriod] = useState(null);
    const [selectedRotation, setSelectedRotation] = useState(null);
    const [addingRotation, setAddingRotation] = useState(false);
    const [newRotationTime, setNewRotationTime] = useState("");
    const [allTimelineData, setAllTimelineData] = useState({});
    const [currentSerial, setCurrentSerial] = useState("");
    const [currentDeviceType, setCurrentDeviceType] = useState("");
    const [newPeriod, setNewPeriod] = useState({
        start: null,
        end: null,
        stock: "",
        material: ""
    });
    const [newStop, setNewStop] = useState({
        start: null,
        end: null,
        stopReason: ""
    })

    const [timelineRefs, setTimelineRefs] = useState({});
    const allTimelineDataRef = useRef({});
    const datePickerRef = useRef(null);
    let timelineMargin = 650;
    if (window.innerWidth < 1900)
        timelineMargin = 650;
    else if (window.innerWidth < 1900)
        timelineMargin = 800;
    else
        timelineMargin = 1000;
    const [timelineWidth, setTimelineWidth] = useState(window.innerWidth - timelineMargin);

    useEffect(() => {
        // Fetch worksite data
        axios.get(`${apiUrl}/api/user/worksite`, {
            headers: { "x-access-token": sessionStorage.getItem("token") },
        })
            .then((res) => {
                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
                if (worksite.length > 0) {
                    setSelectedWorksite(worksite[0].worksiteid);
                }
            });
        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data);
            })

        // Fetch devices data
        let role;
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            role = 'admin'
        }
        else {
            role = 'user';
        }
        axios.get(`${apiUrl}/api/${role}/devicehisto`, {
            headers: { "x-access-token": sessionStorage.getItem("token") },
        })
            .then((res) => {
                setDevices(res.data);
                // console.log(res.data);
            });
    }, []);

    useEffect(() => {
        if (selectedWorksite !== "") {
            const start = new Date(selectedStartDate);
            start.setHours(0, 0, 0, 0);

            const stop = new Date(selectedStartDate);
            stop.setHours(23, 59, 59, 999);

            axios.post(
                `${apiUrl}/api/user/reportprodevent`,
                { startdate: new Date(start), stopdate: new Date(stop), selectedWorksite: selectedWorksite },
                { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                .then(res => {
                    if (res.data.length > 0) {
                        setSerials(res.data.map((item) => item.serial.trim()));
                        setDailyUserDatas(res.data);
                    }
                });

            axios.post(
                `${apiUrl}/api/user/worksitemateriauxstocks`,
                { selectedWorksite: selectedWorksite },
                { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                .then(res => {
                    const received_stocks = res.data.stocks.replace(/[{}]/g, "");;
                    const received_materials = res.data.materiaux.replace(/[{}]/g, "");;
                    setStocks(received_stocks.split(",").map(item => item.trim()));
                    setMaterials(received_materials.split(",").map(item => item.trim()));
                });

            axios.post(
                `${apiUrl}/api/user/worksitestopreasons`,
                { selectedWorksite: selectedWorksite },
                { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                .then(res => {
                    const received_reasons = res.data.reasons.replace(/[{}]/g, "");;
                    setStopReasons(received_reasons.split(",").map(item => item.trim()));
                });
        }
    }, [selectedWorksite, selectedStartDate]);

    useEffect(() => {
        const fetchPeriods = async () => {
            let periods = [];
            if (serials.length > 0) {
                console.log("serials: ", serials);
                try {
                    const results = await Promise.all(
                        serials.map((serial) => getPeriods(serial))
                    );
                    periods = results.filter((result) => result !== null);
                    // console.log("Fetched periods: ", periods);
                } catch (err) {
                    console.error("Error fetching periods:", err.message);
                    periods = [];
                }
            }
            // console.log(periods)
            setAllPeriods(periods); // Mets à jour l'état avec les données récupérées
        };

        fetchPeriods(); // Appelle la fonction asynchrone

        const fetchDeviceNames = async () => {
            const names = {};
            for (const serial of serials) {
                const name = await getNameByDateAndSerial(selectedStartDate, serial);
                names[serial] = name; // Associe le nom à chaque serial
            }
            setDeviceNames(names);
        };

        if (serials.length > 0) {
            fetchDeviceNames();
        }
    }, [serials]);

    useEffect(() => {
        if (allPeriods.length > 0) {
            const timelineDataBySerial = {};
            allPeriods.forEach((item) => {
                timelineDataBySerial[item.serial.trim()] = timelineItem(item);
            });
            setAllTimelineData(timelineDataBySerial);
            allTimelineDataRef.current = timelineDataBySerial;
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
    }, [allPeriods, selectedStartDate]);

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        refreshTimelineData();
    };

    const handleWorksiteChange = (event) => {
        setAllPeriods([]);
        setSerials([]);
        setIsLoading(true);
        setSelectedWorksite(event.target.value);
    };

    function getPictoByTypeDevice(type) {
        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    const getPeriods = async (serial) => {
        const date = GetStartDate(selectedStartDate);
        const startdate = new Date(date).toISOString();
        // const role = sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? 'admin' : 'user';
        try {
            let response = await axios.post(
                `${apiUrl}/api/user/prodperiods`,
                {
                    date_start: startdate,
                    serial: serial
                },
                {
                    headers: {
                        "x-access-token": sessionStorage.getItem("token"),
                        "Content-Type": "application/json"
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(`Error for serial ${serial}:`, error.response?.data || error.message);
            return null;
        }
    };

    // Fonction de snap personnalisée
    function customSnap(date) {
        // Par exemple, arrondir à la minute la plus proche
        const snappedDate = new Date(Math.round(date.getTime() / 1000) * 1000);
        return snappedDate;
    }

    const optionsTimeline = {
        height: '150px',
        maxHeight: '150px',
        minHeight: '150px',
        min: new Date(GetStartDate(selectedStartDate) - 60 * 1000), // Ajoute une marge de 1 minute avant
        max: new Date(GetEndDate(selectedStartDate) + 60 * 1000), // Ajoute une marge de 1 minute après
        stack: false,
        width: `${timelineWidth}px`,
        tooltip: {
            followMouse: true,
        },
        rollingMode: {
            follow: false,
        },
        groupEditable: false,
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1900)
                timelineMargin = 650;
            else if (window.innerWidth < 2400)
                timelineMargin = 800;
            else
                timelineMargin = 1000;

            setTimelineWidth(window.innerWidth - timelineMargin);
        };

        window.addEventListener('resize', handleResize);

        // Nettoyage de l'effet
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function GetEndDate(stopDate) {
        const end = stopDate.setHours(23, 59, 59, 999);
        return end;
    }

    function GetStartDate(startDate) {
        const start = startDate.setHours(1, 0, 0, 0);
        // console.log(start);
        return start;
    }

    function timelineItem(item) {

        if (!item || !item.periods || item.periods.length === 0) {
            return [];
        }

        const matchingData = dailyUserDatas.find((i) => i.serial.trim() === item.serial.trim());

        const periods = item.periods.flatMap((period, periodIx) => {
            const startPeriod = new Date(period.timestamp_start);
            const endPeriod = new Date(period.timestamp_stop);
            const numberPeriod = periodIx + 1;
            // console.log(period);

            if (period.stop_period) {
                // No work period
                const noWorkItem = {
                    id: 'P' + numberPeriod,
                    "id_period": period.id_period,
                    start: startPeriod,
                    end: endPeriod,
                    type: 'range',
                    className: "no-work-range",
                    title: `
                    ${period.stock} de
                    ${formatHeureModal(startPeriod)} à 
                    ${formatHeureModal(endPeriod)}`,
                    group: item.serial.trim(),
                    stock: period.stock,
                    material: period.material,
                    editable: false,
                    device_type: matchingData.device_type,
                }
                return noWorkItem;
            }
            else {
                // Work period
                const periodItem = {
                    id: 'P' + numberPeriod,
                    "id_period": period.id_period,
                    start: startPeriod,
                    end: endPeriod,
                    type: 'range',
                    className: "custom-range",
                    title: `
                    ${period.stock} - ${period.material} de
                    ${formatHeureModal(startPeriod)} à 
                    ${formatHeureModal(endPeriod)}`,
                    group: item.serial.trim(),
                    stock: period.stock,
                    material: period.material,
                    editable: false,
                    device_type: matchingData?.device_type,
                };

                // Collecte des rotations
                let parsed_rotations = [];
                if (typeof period.rotations === 'string' && period.rotations.trim().startsWith('[') && period.rotations.trim().endsWith(']')) {
                    try {
                        parsed_rotations = JSON.parse(period.rotations);
                    } catch (error) {
                        console.error("Erreur de parsing JSON pour 'rotations':", error);
                        // Définir rotations à un tableau vide si le parsing échoue
                        parsed_rotations = [];
                    }
                }
                let rotationItem = [];
                if (parsed_rotations.length > 0) {
                    rotationItem = parsed_rotations.map((rotationTimestamp, rotationIx) => {
                        const startRotation = new Date(rotationTimestamp * 1000);
                        const id = `M${numberPeriod}-R${rotationIx + 1}`;
                        return {
                            id,
                            "id_rotation": item.id_prod_event,
                            "id_period": period.id_period,
                            start: startRotation,
                            group: item.serial.trim(),
                            stock: period.stock,
                            material: period.material,
                            className: 'custom-timelinepoint',
                            type: 'point',
                            // title: `P${numberPeriod}-R${rotationIx + 1} à ${formatHeureModal(startRotation)}`,
                            editable: true,
                        };
                    }) || [];
                }
                return [periodItem, ...rotationItem];
            }
        });
        return periods;
    }

    const handlePeriodClick = (period) => {
        document.activeElement.blur();
        setSelectedPeriod(period); // Stocke les données originales
        setCurrentDeviceType(period.device_type);
        setEditedPeriod({ ...period }); // Initialise avec une copie des données originales
        setModalOpen(true);
    };

    const handleRotationClick = (rotation) => {
        document.activeElement.blur();
        setSelectedRotation(rotation);
        // setEditedRotation({ ...rotation });
        setModalRotationOpen(true);
    }

    const saveCloseModal = () => {
        // handleUpdatePeriod ?
        document.activeElement.blur();
        setModalOpen(false);
        setModalRotationOpen(false);
        setSelectedPeriod(null);
        setEditedPeriod(null);
        setSelectedRotation(null);
        setAddingRotation(false);
    }

    const closeModal = () => {
        document.activeElement.blur();
        setModalOpen(false);
        setModalRotationOpen(false);
        setEditedPeriod(null);
        setSelectedPeriod(null);
        setSelectedRotation(null);
        setAddingRotation(false);
    };

    const openModalCreatePeriod = (serial, deviceType) => {
        setCurrentSerial(serial);
        setCurrentDeviceType(deviceType);
        setModalCreatePeriodOpen(true);
    };

    const closeModalCreatePeriod = () => {
        setModalCreatePeriodOpen(false);
        setNewPeriod({
            start: null,
            end: null,
            stock: "",
            material: ""
        });
    };

    const openModalCreateStop = (serial, deviceType) => {
        setCurrentSerial(serial);
        setCurrentDeviceType(deviceType);
        setModalCreateStopOpen(true);
    };

    const closeModalCreateStop = () => {
        setModalCreateStopOpen(false);
        setNewStop({
            start: null,
            end: null,
            stopReason: "",
        });
    };

    useEffect(() => {
        if (selectedPeriod) {
            // console.log("allPeriods", allPeriods);
            // console.log("selectedPeriod", selectedPeriod);
            // console.log("editedPeriod", editedPeriod);

            const serialPeriods = allPeriods.find(p => p.serial.trim() === selectedPeriod.group.trim()).periods;
            // console.log("serialPeriods", serialPeriods);

            const selectedPeriodStart = selectedPeriod.start;
            const selectedPeriodStop = selectedPeriod.end;
            // console.log("selectedPeriodStart ", selectedPeriodStart, " selectedPeriodStop ", selectedPeriodStop);

            // Conversion en timestamp UNIX (secondes)
            const startTimestamp = Math.floor(new Date(selectedPeriodStart).getTime() / 1000);
            const stopTimestamp = Math.floor(new Date(selectedPeriodStop).getTime() / 1000);
            // console.log("start", startTimestamp, "stop", stopTimestamp);

            const clickedPeriod = serialPeriods.find(
                period => period.start === startTimestamp &&
                    period.stop === stopTimestamp
            );
            // console.log("clickedPeriod", clickedPeriod);
        }
    }, [selectedPeriod])

    // Permet de modifier les champs de la période (stock, matériau, dates)
    const handleUpdatePeriod = async () => {
        if (!editedPeriod) {
            alert("No period selected.");
            return;
        }

        try {
            // Convertir les dates
            let startDateTime = typeof editedPeriod.start === "string"
                ? (() => {
                    const date = new Date(selectedStartDate);
                    const [startHours, startMinutes] = editedPeriod.start.split(":").map(Number);
                    date.setHours(startHours, startMinutes, 0, 0); // Définit heures, minutes, secondes, millisecondes
                    return date;
                })()
                : (() => {
                    const date = new Date(editedPeriod.start);
                    date.setMilliseconds(0); // Force les millisecondes à 0
                    return date;
                })();

            let stopDateTime = typeof editedPeriod.end === "string"
                ? (() => {
                    const date = new Date(selectedStartDate);
                    const [stopHours, stopMinutes] = editedPeriod.end.split(":").map(Number);
                    date.setHours(stopHours, stopMinutes, 0, 0); // Définit heures, minutes, secondes, millisecondes
                    return date;
                })()
                : (() => {
                    const date = new Date(editedPeriod.end);
                    date.setMilliseconds(0); // Force les millisecondes à 0
                    return date;
                })();

            if (stopDateTime <= startDateTime) {
                alert("The stop date cannot be earlier than the start date.");
                return;
            }

            const payload = {
                originalStartDate: selectedPeriod.start,
                startDate: new Date(startDateTime).toISOString(),
                originalStopDate: selectedPeriod.end,
                stopDate: new Date(stopDateTime).toISOString(),
                serial: editedPeriod.group,
                stock: editedPeriod.stock,
                materiau: editedPeriod.material,
                worksite: selectedWorksite,
                periodId: selectedPeriod.id_period,
            };

            const response = await axios.post(`${apiUrl}/api/user/updateperiod`, payload, {
                headers: { "x-access-token": sessionStorage.getItem("token") },
            });

            alert(response.data.messageFR || "Period updated successfully.");
            setModalOpen(false);
            setSelectedPeriod(null);
            setEditedPeriod(null);

            // Rafraîchir les données
            await refreshTimelineData();
        } catch (error) {
            console.error("Error updating period:", error);
            if (error.response && error.response.data && error.response.data.messageFR) {
                alert(error.response.data.messageFR);
            } else {
                alert("Failed to update the period.");
            }
        }
    };

    // Supprimer une rotation pour tomberaux, scraps
    const handleDeleteRotation = async (rotationTimestamp, groupId) => {
        rotationTimestamp = new Date(rotationTimestamp);
        // const confirmed = window.confirm("Are you sure you want to delete this rotation?");
        const confirmed = window.confirm("Êtes vous sûr de vouloir supprimer cette rotation ?");
        if (!confirmed) {
            return;
        }

        try {
            const response = await axios.post(
                `${apiUrl}/api/user/handledeleterotation`,
                {
                    periodId: selectedRotation.id_period,
                    rotation_ts: rotationTimestamp.toISOString(),
                    serial: groupId,
                    worksite: selectedWorksite,
                },
                { headers: { "x-access-token": sessionStorage.getItem("token") } }
            );

            closeModal();
            alert(response.data.messageFR || "Rotation deleted successfully.");
            await refreshTimelineData();
        } catch (error) {
            console.error("Error deleting rotation:", error);
            if (error.response && error.response.data && error.response.data.messageFR) {
                alert(error.response.data.messageFR);
            } else {
                alert("Failed to delete the rotation.");
            }
        }
    };

    const handleAddRotation = async () => {
        if (!selectedPeriod || !newRotationTime) {
            alert("Please provide a valid rotation time.");
            return;
        }

        try {
            // Reformatage de l'heure sélectionnée en timestamp ISO
            const rotationTimestamp = (() => {
                const [hours, minutes] = newRotationTime.split(":").map(Number);
                const rotationDate = new Date(selectedStartDate);
                rotationDate.setHours(hours, minutes, 0, 0);
                return rotationDate;
            })();

            // Requête pour ajouter la rotation
            const payload = {
                periodId: selectedPeriod.id_period,
                rotation_ts: rotationTimestamp,
                serial: selectedPeriod.group,
                device_type: String(selectedPeriod.device_type),
                worksite: String(selectedWorksite),
            };

            const response = await axios.post(
                `${apiUrl}/api/user/handleaddrotation`,
                payload,
                { headers: { "x-access-token": sessionStorage.getItem("token") } }
            );

            closeModal();
            alert(response.data.messageFR || "Rotation added successfully.");
            setAddingRotation(false);
            setNewRotationTime("");
            await refreshTimelineData(); // Rafraîchissement des données
        } catch (error) {
            console.error("Error adding rotation:", error);
            if (error.response && error.response.data && error.response.data.messageFR) {
                alert(error.response.data.messageFR);
            } else {
                alert("Failed to add the rotation.");
            }
        }
    };

    const handleDeletePeriod = async () => {
        if (!selectedPeriod) {
            alert("No period selected");
            return;
        }
        // const confirmed = window.confirm("Are you sure you want to delete this period ?");
        const confirmed = window.confirm("Êtes vous sûr de vouloir supprimer cette période ?");
        if (!confirmed) {
            return;
        }

        try {
            const response = await axios.post(
                `${apiUrl}/api/user/handledeleteperiod`,
                {
                    periodId: selectedPeriod.id_period,
                    serial: selectedPeriod.group,
                    worksite: selectedWorksite
                },
                { headers: { "x-access-token": sessionStorage.getItem("token") } }
            );

            alert(response.data.messageFR);
            setModalOpen(false);
            setSelectedPeriod(null);
            await refreshTimelineData();
        } catch (error) {
            console.error("Error deleting period:", error);
            if (error.response && error.response.data && error.response.data.messageFR) {
                alert(error.response.data.messageFR);
            } else {
                alert("Failed to delete the period.");
            }
        }
    }

    const handleCreatePeriod = async () => {
        let stopPeriod = false;
        if (modalCreatePeriodOpen) {
            if (!newPeriod.start || !newPeriod.end || !newPeriod.stock || !newPeriod.material) {
                alert("All fields must be filled out.");
                return;
            }
        }
        else if (modalCreateStopOpen) {
            stopPeriod = true;
            if (!newPeriod.start || !newPeriod.end || !newPeriod.stock) {
                alert("All fields must be filled out.");
                return;
            }
        }

        // Reformer une date complète
        const startDateTime = new Date(selectedStartDate);
        const [startHours, startMinutes] = newPeriod.start.split(":");
        startDateTime.setHours(startHours, startMinutes, 0, 0);

        const stopDateTime = new Date(selectedStartDate);
        const [stopHours, stopMinutes] = newPeriod.end.split(":");
        stopDateTime.setHours(stopHours, stopMinutes, 0, 0);

        // Vérifier que stopDate > startDate
        if (stopDateTime < startDateTime) {
            alert("La date de fin doit être ultérieure à la date de début pour la période de travail.");
            return;
        }

        try {
            const response = await axios.post(
                `${apiUrl}/api/user/createperiod`,
                {
                    startDate: startDateTime.toISOString(),
                    stopDate: stopDateTime.toISOString(),
                    serial: currentSerial,
                    stock: newPeriod.stock,
                    materiau: newPeriod.material,
                    stop_period: stopPeriod,
                    worksite: selectedWorksite
                },
                { headers: { "x-access-token": sessionStorage.getItem("token") } }
            );

            alert(response.data.messageFR);
            closeModalCreatePeriod();
            closeModalCreateStop();
            // Recharger la page, sans perdre la date actuelle
            await refreshTimelineData();
        } catch (error) {
            console.error("Error creating period:", error);
            if (error.response && error.response.data && error.response.data.messageFR) {
                alert(error.response.data.messageFR);
            } else {
                alert("Failed to create the period.");
            }
        }
    };

    const refreshTimelineData = async () => {
        const start = new Date(selectedStartDate);
        start.setHours(0, 0, 0, 0);

        const stop = new Date(selectedStartDate);
        stop.setHours(23, 59, 59, 999);

        try {
            // Fetch daily user data
            const reportResponse = await axios.post(
                `${apiUrl}/api/user/reportprodevent`,
                { startdate: new Date(start), stopdate: new Date(stop), selectedWorksite },
                { headers: { 'x-access-token': sessionStorage.getItem("token") } }
            );
            setSerials(reportResponse.data.map((item) => item.serial.trim()));
            if (reportResponse.data.length > 0) {
                setDailyUserDatas(reportResponse.data);
            }

            // Fetch periods
            const periodsResults = await Promise.all(
                reportResponse.data.map((item) => getPeriods(item.serial.trim()))
            );
            const periods = periodsResults.filter((result) => result !== null);
            setAllPeriods(periods);

            // Fetch device names
            const names = {};
            for (const serial of reportResponse.data.map((item) => item.serial.trim())) {
                const name = await getNameByDateAndSerial(selectedStartDate, serial);
                names[serial] = name;
            }
            setDeviceNames(names);

            // Update timeline data
            const timelineDataBySerial = {};
            periods.forEach((item) => {
                timelineDataBySerial[item.serial.trim()] = timelineItem(item);
            });
            setAllTimelineData(timelineDataBySerial);
            allTimelineDataRef.current = timelineDataBySerial;
        } catch (error) {
            console.error("Error refreshing timeline data:", error);
        }
    };

    return (
        <div>
            <div>
                <label className="labelStartManageRotation">Date : </label>
                <span className="spanStartManageRotation">
                    <DatePicker
                        locale={fr}
                        dateFormat="dd/MM/yyyy"
                        selected={selectedStartDate}
                        disabled={true}
                    />
                </span>
                <Select
                    className="selectWorksiteManageRotation"
                    value={selectedWorksite}
                    onChange={handleWorksiteChange}
                >
                    {Worksite.map((worksite, index) => (
                        <MenuItem key={index} value={worksite.worksiteid}>
                            {worksite.worksitename}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            {isLoading ? (
                <div className='divLoading'>
                    <img src={Loader} alt="Chargement..." className='imgLoading' />
                </div>
            ) : (allPeriods.length > 0) ? (
                <>
                    <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginTop: "40px" }}>
                        {serials &&
                            allPeriods &&
                            serials.map((serial, index) => {
                                const serialData = allTimelineData[serial];
                                if (!serialData) return null; // Évite de rendre une timeline vide

                                const matchingData = dailyUserDatas.find((i) => i.serial.trim() === serial.trim());
                                const deviceType = matchingData ? matchingData.device_type : 'default-picto';
                                const deviceName = deviceNames[serial] || "Chargement..."; // Nom récupéré ou fallback
                                // console.log("matchingData: ", matchingData, "dailyUserDatas: ", dailyUserDatas);

                                const handleTimelineClick = (properties, serial) => {
                                    const currentAllTimelineData = allTimelineDataRef.current;
                                    const timelineData = currentAllTimelineData[serial];

                                    if (!timelineData) {
                                        console.error(`Aucune donnée trouvée pour le serial ${serial}`);
                                        return;
                                    }

                                    const clickedItem = timelineData.find((item) => item.id === properties.item);

                                    if (clickedItem && properties.item.startsWith('P')) {
                                        handlePeriodClick(clickedItem);
                                    } else if (clickedItem && properties.item.startsWith('M')) {
                                        handleRotationClick(clickedItem);
                                    }
                                };

                                return (
                                    <div key={`${serial}`}>
                                        <div key={`${serial}-${index}`} style={{ padding: "20px", display: "flex", gap: "100px" }}>
                                            {/* Colonne gauche : Pictogramme, nom et bouton */}
                                            {matchingData && (
                                                <div
                                                    style={{
                                                        width: "35%",
                                                        maxWidth: "250px",
                                                        minWidth: "220px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <div style={{ width: "80px", height: "50px" }}>
                                                        <img
                                                            src={require(`Public/images/picto/${getPictoByTypeDevice(deviceType)}`)}
                                                            alt="Vehicle pictogram"
                                                            className="imgPicto"
                                                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                        />
                                                    </div>

                                                    <span style={{ textAlign: "center" }}>
                                                        {deviceName} ({serial})
                                                    </span>
                                                    <Button
                                                        onClick={() => openModalCreatePeriod(serial, deviceType)}
                                                        className="button-add-prod"
                                                    >
                                                        Add prod period
                                                    </Button>
                                                    <Button
                                                        onClick={() => openModalCreateStop(serial, deviceType)}
                                                        className="button-add-stop"
                                                    >
                                                        Add stop period
                                                    </Button>
                                                </div>
                                            )}

                                            {/* Colonne droite : Timeline */}
                                            <div style={{ width: "65%", marginRight: "10%", marginLeft: "1%" }}>
                                                <Box display="flex" justifyContent="center" className="timeline">
                                                    <Timeline
                                                        initialItems={serialData}
                                                        options={{
                                                            ...optionsTimeline,
                                                            editable: {
                                                                add: false, // Désactiver l'ajout d'éléments
                                                                updateTime: false, // Activer l'édition si 'editable' est défini à true dans l'élément
                                                                updateGroup: false, // Désactiver le changement de groupe
                                                                remove: false, // Désactiver la suppression si 'editable' est défini à true
                                                                overrideItems: true, // Utilise la propriété 'editable' de chaque élément
                                                            },
                                                        }}
                                                        clickHandler={(properties) => handleTimelineClick(properties, serial)}
                                                        keys={{ groupIdKey: 'id', itemGroupKey: 'group' }}
                                                    />
                                                </Box>
                                            </div>
                                        </div>

                                        {modalOpen && (
                                            <Dialog
                                                open={modalOpen}
                                                onClose={closeModal}
                                                BackdropProps={{
                                                    style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }, // Arrière-plan légèrement transparent
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        zIndex: 1300, // Priorité élevée pour la modal
                                                        position: "absolute",
                                                    },
                                                }}>
                                                <DialogTitle>
                                                    {selectedPeriod?.id.startsWith("P") ? "Détails de la période" : "Détails de la rotation"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    {selectedPeriod ? (
                                                        <div>
                                                            {/* Début */}
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <label><b>Début :</b></label>
                                                                <span>{formatHeureModal(editedPeriod?.start)}</span>
                                                                <div style={{ width: "24px", height: "24px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Checkbox
                                                                        checked={editedPeriod?.editStart || false}
                                                                        onChange={(e) =>
                                                                            setEditedPeriod((prev) => ({
                                                                                ...prev,
                                                                                editStart: e.target.checked,
                                                                            }))
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {editedPeriod?.editStart && (
                                                                <TextField
                                                                    type="time"
                                                                    value={formatTimeWithTimezone(editedPeriod?.start)}
                                                                    onChange={(e) =>
                                                                        setEditedPeriod((prev) => ({
                                                                            ...prev,
                                                                            start: e.target.value,
                                                                        }))
                                                                    }
                                                                    fullWidth
                                                                />
                                                            )}

                                                            {/* Fin */}
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <label><b>Fin :</b></label>
                                                                <span>{formatHeureModal(editedPeriod?.end)}</span>
                                                                <div style={{ width: "24px", height: "24px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Checkbox
                                                                        checked={editedPeriod?.editEnd || false}
                                                                        onChange={(e) =>
                                                                            setEditedPeriod((prev) => ({
                                                                                ...prev,
                                                                                editEnd: e.target.checked,
                                                                            }))
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {editedPeriod?.editEnd && (
                                                                <TextField
                                                                    type="time"
                                                                    value={formatTimeWithTimezone(editedPeriod?.end)}
                                                                    onChange={(e) =>
                                                                        setEditedPeriod((prev) => ({
                                                                            ...prev,
                                                                            end: e.target.value,
                                                                        }))
                                                                    }
                                                                    fullWidth
                                                                />
                                                            )}

                                                            {/* Stock */}
                                                            <div style={{ marginTop: "15px" }}>
                                                                {editedPeriod.className === 'no-work-range' ?
                                                                    <label><b>Panne :</b></label>
                                                                    : <label><b>Stock :</b></label>
                                                                }

                                                                <Select
                                                                    value={editedPeriod.stock || ""}
                                                                    onChange={(e) =>
                                                                        setEditedPeriod((prev) => ({
                                                                            ...prev,
                                                                            stock: e.target.value,
                                                                        }))
                                                                    }
                                                                    fullWidth
                                                                >
                                                                    {editedPeriod.className === 'custom-range' ?
                                                                        stocks.map((option, index) => (
                                                                            <MenuItem key={index} value={option}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))
                                                                        :
                                                                        stopReasons.map((option, index) => (
                                                                            <MenuItem key={index} value={option}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </div>

                                                            {/* Matériau */}
                                                            {editedPeriod.className === 'no-work-range' ?
                                                                ''
                                                                : <div style={{ marginTop: "15px" }}>
                                                                    <label><b>Matériau :</b></label>
                                                                    <Select
                                                                        value={editedPeriod.material || ""}
                                                                        onChange={(e) =>
                                                                            setEditedPeriod((prev) => ({
                                                                                ...prev,
                                                                                material: e.target.value,
                                                                            }))
                                                                        }
                                                                        fullWidth
                                                                    >
                                                                        {materials.map((option, index) => (
                                                                            <MenuItem key={index} value={option}>
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            }

                                                            {editedPeriod.className === 'no-work-range' ?
                                                                ''
                                                                : ((selectedPeriod.device_type >= 10 && selectedPeriod.device_type <= 16) || selectedPeriod.device_type === 41) && (
                                                                    <div style={{ marginTop: "15px", color: "black" }}>
                                                                        <Checkbox
                                                                            checked={addingRotation}
                                                                            onChange={(e) => setAddingRotation(e.target.checked)}
                                                                        />
                                                                        <label style={{ marginLeft: "5px" }}>Add Rotation</label>

                                                                        {/* Formulaire pour la rotation s'affiche directement en dessous si la checkbox est cochée */}
                                                                        {addingRotation && (
                                                                            <div style={{ marginTop: "20px" }}>
                                                                                <TextField
                                                                                    type="time"
                                                                                    label="Rotation Time"
                                                                                    value={newRotationTime}
                                                                                    onChange={(e) => setNewRotationTime(e.target.value)}
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    fullWidth
                                                                                    inputProps={{
                                                                                        min: formatTimeWithTimezone(selectedPeriod.start),
                                                                                        max: formatTimeWithTimezone(selectedPeriod.end),
                                                                                    }}
                                                                                />
                                                                                <Button
                                                                                    onClick={handleAddRotation}
                                                                                    className='button-saveclose'
                                                                                    style={{ marginTop: "10px" }}
                                                                                >
                                                                                    Save Rotation
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    ) : (
                                                        <p>Aucune donnée</p>
                                                    )}
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={handleDeletePeriod}
                                                        className='button-delete'
                                                    >
                                                        Delete Period
                                                    </Button>
                                                    <Button
                                                        onClick={handleUpdatePeriod}
                                                        className='button-saveclose'
                                                    >
                                                        Save & Close
                                                    </Button>
                                                    <Button
                                                        onClick={closeModal}
                                                        className='button-close'
                                                    >
                                                        Cancel
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                        {modalCreatePeriodOpen && (
                                            <Dialog
                                                open={modalCreatePeriodOpen}
                                                onClose={closeModalCreatePeriod}
                                                BackdropProps={{
                                                    style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        zIndex: 1300,
                                                        position: "absolute",
                                                        minWidth: "400px"
                                                    }
                                                }}
                                            >
                                                <DialogTitle>Create a new period</DialogTitle>
                                                <DialogContent>
                                                    {/* Début */}
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }}>
                                                        <label><b>Start Time:</b></label>
                                                        <TextField
                                                            type="time"
                                                            value={newPeriod.start || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, start: e.target.value })}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    {/* Fin */}
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }}>
                                                        <label><b>End Time:</b></label>
                                                        <TextField
                                                            type="time"
                                                            value={newPeriod.end || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, end: e.target.value })}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    {/* Stock */}
                                                    <div style={{ marginBottom: "15px" }}>
                                                        <label><b>Stock:</b></label>
                                                        <Select
                                                            value={newPeriod.stock || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, stock: e.target.value })}
                                                            fullWidth
                                                        >
                                                            {stocks.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </div>

                                                    {/* Matériau */}
                                                    <div style={{ marginBottom: "15px" }}>
                                                        <label><b>Material:</b></label>
                                                        <Select
                                                            value={newPeriod.material || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, material: e.target.value })}
                                                            fullWidth
                                                        >
                                                            {materials.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleCreatePeriod} className='button-saveclose'>Save & Close</Button>
                                                    <Button onClick={closeModalCreatePeriod} className='button-close'>Cancel</Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                        {modalCreateStopOpen && (
                                            <Dialog
                                                open={modalCreateStopOpen}
                                                onClose={closeModalCreateStop}
                                                BackdropProps={{
                                                    style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        zIndex: 1300,
                                                        position: "absolute",
                                                        minWidth: "400px"
                                                    }
                                                }}
                                            >
                                                <DialogTitle>Create a new stop period</DialogTitle>
                                                <DialogContent>
                                                    {/* Début */}
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }}>
                                                        <label><b>Start Time:</b></label>
                                                        <TextField
                                                            type="time"
                                                            value={newPeriod.start || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, start: e.target.value })}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    {/* Fin */}
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }}>
                                                        <label><b>End Time:</b></label>
                                                        <TextField
                                                            type="time"
                                                            value={newPeriod.end || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, end: e.target.value })}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    {/* Stop Reason */}
                                                    <div style={{ marginBottom: "15px" }}>
                                                        <label><b>Stop Reason:</b></label>
                                                        <Select
                                                            value={newPeriod.stock || ""}
                                                            onChange={(e) => setNewPeriod({ ...newPeriod, stock: e.target.value })}
                                                            fullWidth
                                                        >
                                                            {stopReasons.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleCreatePeriod} className='button-saveclose'>Save & Close</Button>
                                                    <Button onClick={closeModalCreateStop} className='button-close'>Cancel</Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                        {modalRotationOpen && (
                                            <Dialog
                                                open={modalRotationOpen}
                                                onClose={closeModal}
                                                BackdropProps={{
                                                    style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }, // Arrière-plan légèrement transparent
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        zIndex: 1300, // Priorité élevée pour la modal
                                                        position: "absolute",
                                                    },
                                                }}>
                                                <DialogTitle>
                                                    {selectedRotation?.id.startsWith("M") ? "Rotation details" : ""}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <div>
                                                        {/* Timestamp */}
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <label><b>Heure :</b></label>
                                                            <span>{formatHeureModal(selectedRotation?.start)}</span>
                                                        </div>

                                                        {/* Stock */}
                                                        <div style={{ marginTop: "15px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <label><b>Stock : </b></label>
                                                            <span>{selectedRotation?.stock}</span>
                                                        </div>

                                                        {/* Matériau */}
                                                        <div style={{ marginTop: "15px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <label><b>Material : </b></label>
                                                            <span>{selectedRotation?.material}</span>
                                                        </div>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => handleDeleteRotation(selectedRotation.start, selectedRotation.group)}
                                                        className='button-delete'
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button onClick={closeModal} className='button-close'>Cancel</Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </>
            ) : (
                <p className='pNoData'>Aucune donnée disponible.</p>
            )
            }
        </div>
    );
}

export default ManageRotationV2;