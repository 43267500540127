import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from '@mui/material';
import useWebSocket from "react-use-websocket"
import DeleteIcon from '@mui/icons-material/Delete';
import {
    faDownload,
    faTerminal,
    faMapMarkerAlt,
    faBars,
    faChessKnight, faSdCard, faSitemap, faSimCard, faLaptop, faWalkieTalkie,
    faBatteryFull, faBatteryThreeQuarters, faBatteryHalf, faBatteryQuarter, faBatteryEmpty, faDrawPolygon
} from "@fortawesome/free-solid-svg-icons";
import { Toast, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import ReactDOMServer from "react-dom/server";
import Leaflet from "leaflet";

const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;
var serial_toshowInModal = 'nope';

function Dashboard() {

    const [Connected, setConnected] = useState([]);
    const [Picto, setPicto] = useState([]);
    const [worksitebyclient, setWorksiteByClient] = useState([]);
    const [deviceByClient, setDeviceByClient] = useState([]);
    const [showDevPositionModal, setShowDevPositionModal] = useState(false);
    const [showKeepAlive, setShowModalKeepAlive] = useState(false);
    const [connectedClients, setConnectedClients] = useState([]);
    const [AssociatedDevices, setAssociatedDevices] = useState([]);
    const [UserSerialsandNames, setUserSerialsAndNames] = useState([]);
    const navigate = useNavigate();
    const userid = sessionStorage.getItem("userId")
    const channelname = "connected";
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname },
            share: false,
            shouldReconnect: () => true,
        },
    )

    const iconHTML2 = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size="3x"
        style={{ color: 'blue' }}></FontAwesomeIcon>)
    const customMarkerIconblue = new Leaflet.DivIcon({
        html: iconHTML2,
    });

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setConnected(lastJsonMessage)
        }
    }, [lastJsonMessage])

    useEffect(() => {
        axios.get(apiUrl + `/api/admin/devicebyclient`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setDeviceByClient(res.data);
            })

        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data)
            })

        axios.get(apiUrl + `/api/user/worksitebyclient`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const worksitebyclient = res.data;
                setWorksiteByClient(worksitebyclient);
            })
    }, []);

    function getSerialbyWorksite(latitude, longitude) {
        let worksite = 'unknown';

        for (const work of worksitebyclient) {
            const positionRefObj = JSON.parse(work.positionref);
            const { lat, long } = positionRefObj;
            const latParsed = parseFloat(lat);
            const lonParsed = parseFloat(long);
            if (isInRadius(latParsed, lonParsed, latitude, longitude, work.rayon)) {
                worksite = work.worksitename;
            }
        }

        return worksite;
    }

    function getNamebySerial(serial) {
        if (UserSerialsandNames.length > 0) {
            const device = UserSerialsandNames.find(device => device.username === serial);
            let NameDevice = JSON.parse(device.name_device);
            let lastDevice;
            if (NameDevice.length > 1)
                lastDevice = NameDevice[NameDevice.length - 1].nameDevice;
            else
                lastDevice = NameDevice.nameDevice


            let str = lastDevice;
            if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN"))
                str = str + '(' + serial + ')';
            return str;
        }
    }

    function getPictoByTypeDevice(type) {
        if (type !== undefined) {
            const picto = Picto.find(device => device.id_device_type === type);
            if (picto !== undefined)
                return picto.picto;
        }

    }

    function getPictoCount() {
        let maxId = -Infinity; // Initialisation à une valeur très petite
        for (const item of Picto) {
            if (item.picto !== null && item.id_device_type > maxId) {
                maxId = item.id_device_type;
            }
        }
        return maxId
    }

    useEffect(() => {
        const connectedClientSet = new Set();
        Object.values(Connected).forEach(({ SerialStr }) => {
            const connectedClient = deviceByClient.find(device => device.username === SerialStr)?.client;
            if (connectedClient) {
                connectedClientSet.add(connectedClient.nomClient);
            }
        });
        const uniqueConnectedClients = Array.from(connectedClientSet).map(nomClient => {
            return { nomClient };
        });

        uniqueConnectedClients.sort((a, b) => a.nomClient.localeCompare(b.nomClient));
        setConnectedClients(uniqueConnectedClients);
        // console.log(Connected);
        const associatedDevices = Object.entries(Connected).map(([serial, connectedDevice]) => {
            
            let client = deviceByClient.find(device => device.username === serial);
            let worksite;
            if (worksitebyclient.length > 0) {
                worksite = getSerialbyWorksite(connectedDevice.last_keepAlive.current_position.latitude, connectedDevice.last_keepAlive.current_position.longitude);
            }
            if (client)
                client = client.client.nomClient;
            else
                client = "";
            return {
                client,
                connectedDevice,
                worksite
            };
        });
        setAssociatedDevices(associatedDevices)
    }, [Connected, worksitebyclient, deviceByClient]);

    useEffect(() => {

        axios.get(apiUrl + '/api/admin/serialsforadmin', {
            headers: { 'x-access-token': sessionStorage.getItem('token') }
        })
            .then(res => {
                setUserSerialsAndNames(res.data)
            })
    }, [])

    const handleModalClose = () => {
        setShowDevPositionModal(false)
    }
    const handleModalKeepAliveClose = () => {
        setShowModalKeepAlive(false)
    }
    const handleModalOpen = (serial) => {
        serial_toshowInModal = serial;
        setShowDevPositionModal(true)
    }
    const handleModalOpenKeepAlive = (serial) => {
        serial_toshowInModal = serial;
        setShowModalKeepAlive(true)
    }

    function Goto_DeviceInfo(serial, keepAlive) {
        navigate('/admin/InfoDevice/' + serial, {
            state: {
                keepAlive: keepAlive
            }
        });
    }

    function isInRadius(lat1, lon1, lat2, lon2, rayonMetres) {
        const rayonTerreMetres = 6371000.0;

        // Conversion des latitudes et longitudes en radians
        const lat1Rad = degreeToRadian(lat1);
        const lon1Rad = degreeToRadian(lon1);
        const lat2Rad = degreeToRadian(lat2);
        const lon2Rad = degreeToRadian(lon2);

        // Calcul de la différence des latitudes et des longitudes
        const deltaLat = lat2Rad - lat1Rad;
        const deltaLon = lon2Rad - lon1Rad;

        // Formule de la distance haversine
        const a = Math.pow(Math.sin(deltaLat / 2.0), 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.pow(Math.sin(deltaLon / 2.0), 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));


        // Distance en mètres
        const distanceMetres = rayonTerreMetres * c;
        // Vérifier si la distance est inférieure ou égale au rayon de référence
        return distanceMetres <= rayonMetres;
    }

    // Fonction pour convertir les degrés en radians
    function degreeToRadian(angle) {
        return Math.PI * angle / 180.0;
    }

    function getTimeDifference(date) {
        const now = new Date();
        const timeDifference = now - new Date(date);

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;

        const seconds = Math.floor((timeDifference / second) % 60);
        const minutes = Math.floor((timeDifference / minute) % 60);
        const hours = Math.floor((timeDifference / hour) % 24);
        const days = Math.floor(timeDifference / day);

        let result = '';
        if (days > 0) {
            result += `${days}j `;
        }
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0) {
            result += `${minutes}m `;
        }
        if (seconds > 0) {
            result += `${seconds}s `;
        }

        return result.trim();
    }

    function getPositionRefWorksite(worksiteName) {

        if (worksiteName !== "unknown") {
            const worksite = worksitebyclient.find(site => site.worksitename === worksiteName);
            if (worksite) {
                // Si le site est trouvé, extraire la latitude, la longitude et le rayon
                const { lat, long } = JSON.parse(worksite.positionref);

                // Retourner une chaîne avec latitude, longitude et rayon
                return ` (Lat : ${lat},  Long: ${long}, Rayon: ${worksite.rayon})`;
            }
        }
    }
    function BatteryStatus(soc_bat) {
        const batteryLevel = soc_bat;

        let batteryIcon;

        if (batteryLevel > 70) {
            batteryIcon = faBatteryFull;
        } else if (batteryLevel > 20) {
            batteryIcon = faBatteryHalf;
        }
        else
            batteryIcon = faBatteryEmpty;

        return batteryIcon;
    }

    function BatteryColor(soc_bat) {
        const batteryLevel = soc_bat;

        let color;

        if (batteryLevel > 70) {
            color = 'green';
        } else if (batteryLevel > 20) {
            color = 'orange';
        } else
            color = 'red'

        return color;
    }

    const uniqueSites = [...new Set(AssociatedDevices.map(device => device.worksite))];

    return (
        <div>
            <Link to={'/admin/Sim'}><Button title='Sim' style={{ marginLeft: '1%', width: '15%' }}> <FontAwesomeIcon icon={faSimCard} /> </Button></Link>
            <Link to={'/admin/testK1'}><Button title='Test' style={{ marginLeft: '1%', width: '15%' }}> <FontAwesomeIcon icon={faSimCard} /> </Button></Link>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {connectedClients.map((client, index) => (
                    <Accordion key={index} defaultExpanded={true} style={{ width: '100%', marginTop: '1%' }}>
                        <AccordionSummary>
                            <Typography>{client.nomClient}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex", flexWrap: "wrap", width: '100%' }}>
                            {uniqueSites.filter(site => AssociatedDevices.some(device => device.worksite === site && device.client === client.nomClient)).map((site, siteIndex) => (
                                <Accordion key={siteIndex} defaultExpanded={true} style={{ width: '100%', marginTop: '1%' }}>
                                    <AccordionSummary>
                                        <Typography>{site}{getPositionRefWorksite(site)}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ display: "flex", align: "center", flexWrap: "wrap", width: '100%' }}>
                                        {AssociatedDevices.filter(device => device.client === client.nomClient && device.worksite === site).sort((a, b) => {
                                            // Logique de tri
                                        }).sort((a, b) => {
                                            // Compare les périphériques en fonction de leur état de connexion et de la date de connexion/déconnexion
                                            if (a.connectedDevice.IsConnected && b.connectedDevice.IsConnected) {
                                                // Si les deux périphériques sont connectés, les trier par date de connexion
                                                return new Date(a.connectedDevice.connection_Date) - new Date(b.connectedDevice.connection_Date);
                                            } else if (!a.connectedDevice.IsConnected && !b.connectedDevice.IsConnected) {
                                                // Si les deux périphériques sont déconnectés, les trier par date de déconnexion
                                                return new Date(b.connectedDevice.disconnection_Date) - new Date(a.connectedDevice.disconnection_Date);
                                            } else if (a.connectedDevice.IsConnected && !b.connectedDevice.IsConnected) {
                                                // Mettre les périphériques connectés avant les déconnectés
                                                return -1;
                                            } else {
                                                // Mettre les périphériques déconnectés après les connectés
                                                return 1;
                                            }
                                        }).map((device, deviceId) => (
                                            <Toast key={deviceId} style={{ marginLeft: '1%', marginTop: "1%", backgroundColor: device.connectedDevice.last_keepAlive.error_screen_view_active !== 0 ? 'RED' : 'WHITE' }}>
                                                <Toast.Header closeButton={false} style={{ backgroundColor: device.connectedDevice.IsConnected ? '#00FF00' : '#ff6666', display: 'flex', alignItems: 'center' }}>
                                                    {Picto.length > 0 && device.connectedDevice.last_keepAlive ? getPictoByTypeDevice(device.connectedDevice.last_keepAlive.current_CB_type) !== undefined ? <img style={{ width: '15%' }}
                                                        src={require("Public/images/picto/" + getPictoByTypeDevice(device.connectedDevice.last_keepAlive.current_CB_type))}
                                                        alt=""></img> : "" : ""}
                                                    <strong style={{ fontWeight: 'bold', color: 'black', margin: 0 }} className="me-auto">{device.connectedDevice.SerialStr}<span style={{ fontSize: '0.8em' }}> ({getNamebySerial(device.connectedDevice.SerialStr)}) </span></strong>
                                                    <small style={{ fontWeight: 'bold', color: 'black', margin: 0 }}>{device.connectedDevice.IsConnected ? ChangeDate(device.connectedDevice.connection_Date) : getTimeDifference(device.connectedDevice.disconnection_Date)}</small>
                                                </Toast.Header>
                                                <Toast.Body>
                                                    {device.connectedDevice.last_keepAlive.current_CB_type > getPictoCount() ? <p style={{ color: 'red' }}>Error type CB  : {device.connectedDevice.last_keepAlive.current_CB_type}</p> : ""}
                                                    {device.connectedDevice.dev_type !== 4 ? (
                                                        <p>
                                                            Main: V{device.connectedDevice.last_keepAlive.version_main} -- LCD: V{device.connectedDevice.last_keepAlive.version_screen} -- BT: V{device.connectedDevice.last_keepAlive.version_bt}
                                                        </p>
                                                    ) : (
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p>Main: V{device.connectedDevice.last_keepAlive.version_main}</p>
                                                            {device.connectedDevice.IsConnected && (
                                                                <FontAwesomeIcon
                                                                    title={device.connectedDevice.last_keepAlive.soc_bat + "%"}
                                                                    style={{
                                                                        color: BatteryColor(device.connectedDevice.last_keepAlive.soc_bat),
                                                                        fontSize: '2rem',
                                                                    }}
                                                                    icon={BatteryStatus(device.connectedDevice.last_keepAlive.soc_bat)}
                                                                />
                                                            )}
                                                        </div>
                                                    )}

                                                    {device.connectedDevice.IsConnected ? <p>lastKeepAlive: {getTimeDifference(device.connectedDevice.last_keepAlive.timestamp)} {device.connectedDevice.dev_type === 1 && (<> -- View: {device.connectedDevice.last_keepAlive.get_current_view} </>)}</p> : ""}
                                                    {device.connectedDevice.IsConnected && device.connectedDevice.dev_type === 1 ? <Button title='Download File' style={{ width: '15%' }} onClick={() => DownloadFile(device.connectedDevice.SerialStr)}><FontAwesomeIcon icon={faDownload} /></Button> : ""}
                                                    {device.connectedDevice.IsConnected ? <Link to={'/admin/Terminal/' + device.connectedDevice.SerialStr}><Button title='Terminal' style={{ marginLeft: '1%', width: '15%' }}> <FontAwesomeIcon icon={faTerminal} /> </Button></Link> : ""}
                                                    {device.connectedDevice ? <Button title='Localise device' style={{ marginLeft: '1%', width: '15%', color: device.connectedDevice.last_keepAlive.gps_status ? '' : 'red' }} onClick={() => handleModalOpen(device.connectedDevice.SerialStr)}><FontAwesomeIcon icon={faMapMarkerAlt} /></Button> : ''}
                                                    {device.connectedDevice.IsConnected ? <Link to={'/admin/EnginUpdate/' + device.connectedDevice.SerialStr}><Button title='Firmwares' style={{ marginLeft: '1%', width: '15%', color: device.connectedDevice.dev_type === 4 ? '' : device.connectedDevice.last_keepAlive.UWB_capteurs_ok ? '' : 'red' }}> <FontAwesomeIcon icon={faChessKnight} /> </Button></Link> : device.connectedDevice.dev_type === 1 ? <Button title='Firmwares' disabled style={{ marginLeft: '1%', width: '15%', color: device.connectedDevice.last_keepAlive.UWB_capteurs_ok ? '#3473F7' : 'red' }}><FontAwesomeIcon icon={faChessKnight} /></Button> : ""}
                                                    {device.connectedDevice.IsConnected && device.connectedDevice.dev_type === 1 ? <Link to={'/admin/SdExplorer/' + device.connectedDevice.SerialStr}><Button title='sdExplorer' style={{ marginLeft: '1%', width: '15%' }}> <FontAwesomeIcon icon={faSdCard} /> </Button></Link> : ""}
                                                    {device.connectedDevice.IsConnected && device.connectedDevice.dev_type === 1 ? <Link to={'/admin/Worksite/' + device.connectedDevice.SerialStr}><Button title='Worksite' style={{ marginLeft: '1%', width: '15%' }}> <FontAwesomeIcon icon={faSitemap} /> </Button></Link> : ""}
                                                    {device.connectedDevice.IsConnected && device.connectedDevice.dev_type === 1 ? <Button title='DeviceInfo' style={{ marginTop: '1%', width: '15%' }} onClick={() => Goto_DeviceInfo(device.connectedDevice.SerialStr, device.connectedDevice.last_keepAlive)}><FontAwesomeIcon icon={faLaptop} /> </Button> : ""}
                                                    {device.connectedDevice.IsConnected && device.connectedDevice.dev_type === 1 ? <Link to={'/admin/EnginBT/' + device.connectedDevice.SerialStr}><Button title='BTEngin' style={{ marginLeft: '1%', width: '15%' }}> <FontAwesomeIcon icon={faWalkieTalkie} /> </Button></Link> : ""}
                                                    {device.connectedDevice.isUploading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}><progress max={device.connectedDevice.upload_filesize} value={device.connectedDevice.upload_size_sended}></progress><h6 style={{ margin: '3%' }}>Uploading...</h6></div> : ""}
                                                    {device.connectedDevice ? <Button title='KeepAlive' style={{ marginLeft: '1%', width: '15%' }} onClick={() => handleModalOpenKeepAlive(device.connectedDevice.SerialStr)}><FontAwesomeIcon icon={faBars} /></Button> : ''}
                                                    {(device.connectedDevice.upload_result > 6) ? <div style={{ display: "flex" }}><h6 style={{ marginTop: '1%' }}>Last Upload Error : {device.connectedDevice.upload_result}</h6>
                                                        <IconButton style={{ width: '2%', marginLeft: '20px' }} aria-label="delete" size="small" onClick={() => EraseUploadStatus(device.connectedDevice.SerialStr)}><DeleteIcon fontSize="small" /></IconButton></div>
                                                        : ""}
                                                    {device.connectedDevice.IsConnected && device.connectedDevice.dev_type === 1 ? <Link to={'/admin/DangersFixes/' + device.worksite}><Button title='DangersFixes' style={{ marginLeft: '1%', width: '15%', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}> <FontAwesomeIcon icon={faDrawPolygon} className='fa-xl' /> </Button></Link> : ""}

                                                </Toast.Body>
                                            </Toast>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
            <Modal size="lg" show={showKeepAlive} onHide={handleModalKeepAliveClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Position du device {serial_toshowInModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre><h3>KeepAlive</h3> {JSON.stringify(Connected[serial_toshowInModal], null, 2)}</pre>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showDevPositionModal} onHide={handleModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Position du device {serial_toshowInModal} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.entries(Connected).map(([id, { SerialStr, last_keepAlive }]) => (
                        <div key={id} style={{ marginLeft: '1%' }}>
                            {(SerialStr === serial_toshowInModal) ?
                                last_keepAlive.gps_status ?
                                    <div>
                                        <p>Lat: {last_keepAlive.current_position.latitude} Long: {last_keepAlive.current_position.longitude} Date: {getDate(last_keepAlive.timestamp)}</p>
                                        <MapContainer center={[last_keepAlive.current_position.latitude, last_keepAlive.current_position.longitude]} zoom={16} scrollWheelZoom={true} style={{ width: '100%', height: '500px' }}>
                                            <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={22}
                                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                                            <Marker icon={customMarkerIconblue} position={[last_keepAlive.current_position.latitude, last_keepAlive.current_position.longitude]} />
                                        </MapContainer>
                                    </div>
                                    : <p>No GPS fix</p>
                                : ""}
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

function getDate(isodate) {
    var dt = new Date(isodate);
    var off = dt.getTimezoneOffset() * 60000
    var newdt = new Date(dt - off).toISOString()
    return newdt.slice(0, 19).toString().replace('T', ' ');
}

function EraseUploadStatus(serial) {
    let cmd = serial + ';resetUploadStatus'
    axios.post(apiUrl + '/api/admin/brokRaw', {
        cmd: cmd,
    }, {
        headers: { 'x-access-token': sessionStorage.getItem("token") }
    })
        .then(function (response) {
            // console.log(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

function ChangeDate(date) {
    let dateStr = new Date(date);
    let year = dateStr.getFullYear();
    let month = dateStr.getMonth() + 1;
    let dt = dateStr.getDate();
    let hours = dateStr.getHours();
    let minutes = dateStr.getMinutes();
    let seconds = dateStr.getSeconds();

    if (hours < 10) {
        hours = '0' + hours;
    }

    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return year + '-' + month + '-' + dt + " " + hours + ":" + minutes + ":" + seconds;
}

function DownloadFile(serial) {
    axios.post(apiUrl + '/api/admin/Download', {
        serial: serial,
    }, {
        headers: { 'x-access-token': sessionStorage.getItem("token") }
    })
        .then(function (response) {
            alert(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export default Dashboard;